<template>
  <lab-task
    ><lab-sim
      lab-name="electrochem-lab"
      :random-scaling-factor-seed="seed"
      @allGuidanceComplete="submitResponse"
  /></lab-task>
</template>

<script>
import {CompletableSimMixin} from '@/tasks/mixins/completable-sim';
import LabSim from '@/tasks/components/simulations/LabSim.vue';
import LabTask from '@/tasks/components/dynamic-questions/labs/LabTask.vue';
import {defineComponent} from '@vue/composition-api';

export default defineComponent({
  name: 'Electrochem',
  components: {LabSim, LabTask},
  mixins: [CompletableSimMixin()],

  computed: {
    seed() {
      return this.taskState.getValueBySymbol('seed').content.value;
    },
  },
});
</script>
